/*
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-13 09:54:37
 * @LastEditTime: 2023-05-12 18:05:39
 * @Descripttion: 内容管理模块接口
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/school/plaschmessagesend/"; // 通知公告
const PREFIX2 = "/school/plaremark/"; // 评语
const PREFIX3 = "/school/plainformation/"; // 文章
const PREFIX4 = "/school/schcourses/"; // 教材管理
const PREFIX5 = "/school/schcourseschapter/"; // 教材管理(章节)
const PREFIX6 = "/school/teateaching/"; // 备课管理
const PREFIX7 = "/school/teateachingknowledge/"; // 知识点
const PREFIX8 = "/school/teateachinghomework/"; // 作业
const PREFIX9 = "/school/teateachingcourseware/"; //课件
const PREFIX10 = "/school/teateachingpractice/"; //课堂练习

/* 教材管理 */

/*******
 * @Descripttion: 教材列表
 * @Author: Dyf
 * @param {object} params 筛选参数
 * @param {Number} pagesize 每页条数
 * @return {*}
 */
export async function $textbookList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX4}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材新增
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $textbookAdd(params) {
  try {
    return await HTTP.post(`${PREFIX4}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材共享
 * @Author: Dyf
 * @param {number} sccou_id 教材id

 * @return {*}
 */
export async function $textbookShartet(sccou_id) {
  try {
    return await HTTP.post(`${PREFIX4}arraign_courses`, {sccou_id});
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材编辑
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $textbookEdit(params) {
  try {
    return await HTTP.post(`${PREFIX4}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 修改教材状态
 * @Author: Dyf
 * @param {number} sccou_id 教材id
 * @param {number} type 教材状态
 * @return {*}
 */
export async function $textbookStatusChange(sccou_id, type) {
  try {
    return await HTTP.post(`${PREFIX4}change_status`, { sccou_id, type });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材详情
 * @Author: Dyf
 * @param {number} sccou_id 教材id
 * @return {*}
 */
export async function $textbookInfo(sccou_id) {
  try {
    return await HTTP.post(`${PREFIX4}info`, { sccou_id });
  } catch (error) {
    return error;
  }
}

/* 教材管理(章节) */

/*******
 * @Descripttion: 章节列表
 * @Author: Dyf
 * @param {number} sccou_id 教材id
 * @param {string} query_word 搜索关键字
 * @return {*}
 */
export async function $chaptersList(sccou_id, query_word) {
  try {
    return await HTTP.post(
      `${PREFIX5}list_data`,
      { sccou_id, query_word },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 章节添加
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $chaptersAdd(params) {
  try {
    return await HTTP.post(`${PREFIX5}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 章节编辑
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $chaptersEdit(params) {
  try {
    return await HTTP.post(`${PREFIX5}edit`, params);
  } catch (error) {
    return error;
  }
}

/* 文章管理 */

/**
 * @LastEditors:whg
 * 文章列表
 * @param {number} params
 * @param {number} pagesize
 * @returns
 */
export async function articleList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      PREFIX3 + "list",
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}
/**
 * 添加文章
 * @param {object} params
 * @returns
 */
export async function articleAdd(params) {
  try {
    return await HTTP.post(PREFIX3 + "add", params);
  } catch (error) {
    return error;
  }
}
/**
 * 编辑文章
 * @param {object} params
 * @returns
 */
export async function articleEdit(params) {
  try {
    return await HTTP.post(PREFIX3 + "edit", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除文章
 * @param {number} plinf_id 文章id
 * @returns
 */
export async function articleRemove(plinf_id) {
  try {
    return await HTTP.post(PREFIX3 + "delete", { plinf_id });
  } catch (error) {
    return error;
  }
}
/**
 * 发布文章
 * @param {number} plinf_id 文章id
 * @returns
 */
export async function articlePublish(plinf_id) {
  try {
    return await HTTP.post(PREFIX3 + "publish", { plinf_id });
  } catch (error) {
    return error;
  }
}
/**
 * 撤回文章
 * @param {number} plinf_id 文章id
 * @returns
 */
export async function articleRecall(plinf_id) {
  try {
    return await HTTP.post(PREFIX3 + "recall", { plinf_id });
  } catch (error) {
    return error;
  }
}
/**
 * 文章详情
 * @param {number} plinf_id 文章id
 * @returns
 */
export async function articleInfo(plinf_id) {
  try {
    return await HTTP.post(PREFIX3 + "info", { plinf_id });
  } catch (error) {
    return error;
  }
}

/* 评语管理 */

/**
 * @LastEditors:whg
 * 评语列表
 * @param {object} params
 * @returns
 */
export async function remarkList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      PREFIX2 + "list",
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}
/**
 * 评语编辑
 * @param {object} params
 * @returns
 */
export async function remarkEdit(params) {
  try {
    return await HTTP.post(PREFIX2 + "edit", params);
  } catch (error) {
    return error;
  }
}
/**
 * 评语详情
 * @param {number} plrem_id 评语id
 * @returns
 */
export async function remarkInfo(plrem_id) {
  try {
    return await HTTP.post(PREFIX2 + "info", { plrem_id });
  } catch (error) {
    return error;
  }
}
/**
 * 评语删除
 * @param {number} plrem_id 评语ids
 * @returns
 */
export async function remarkRemove(plrem_id) {
  try {
    return await HTTP.post(PREFIX2 + "delete", { plrem_id });
  } catch (error) {
    return error;
  }
}

/* 通知管理 */

/**
 * 文章列表
 * @param {number} params
 * @param {number} pagesize
 * @returns
 */
export async function messageList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      PREFIX + "list",
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}
/**
 * 添加通知
 * @param {object} params
 * @returns
 */
export async function messageAdd(params) {
  try {
    return await HTTP.post(PREFIX + "add", params);
  } catch (error) {
    return error;
  }
}
/**
 * 编辑通知
 * @param {object} params
 * @returns
 */
export async function messageEdit(params) {
  try {
    return await HTTP.post(PREFIX + "edit", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除通知
 * @param {number} plsch_messen_id 消息id
 * @returns
 */
export async function messageRemove(plsch_messen_id) {
  try {
    return await HTTP.post(PREFIX + "delete", { plsch_messen_id });
  } catch (error) {
    return error;
  }
}
/**
 * 通知详情
 * @param {number} plsch_messen_id 消息id
 * @returns
 */
export async function messageInfo(plsch_messen_id) {
  try {
    return await HTTP.post(PREFIX + "info", { plsch_messen_id });
  } catch (error) {
    return error;
  }
}
/**
 * 发布通知
 * @param {number} plsch_messen_id 消息id
 * @returns
 */
export async function messagePublish(plsch_messen_id) {
  try {
    return await HTTP.post(PREFIX + "publish", { plsch_messen_id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 备课列表
 * @Author: lina
 * @param {object} params 筛选参数
 * @param {Number} pagesize 每页条数
 * @return {*}
 */
export async function teateachingList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
        `${PREFIX6}list`,
        { ...params, pagesize },
        { load: true }
    );
  } catch (error) {
    return error;
  }
}

/**
 * 获取配置参数组合
 * @param {object} code
 */
export async function getGroupData(code) {
  try {
    return await HTTP.post(
        `${PREFIX6}getgroupdata`,
        {
          code: code,
        }
    );
  } catch (error) {
    return error;
  }
}

/**
 * 获取教师备课表详情
 * @author 四川创企
 * @DateTime 2024/05/06
 * @param {number} id
 */
export async function getTeaTeachingInfo(id) {
  try {
    return await HTTP.post(
        `${PREFIX6}info`,
        {
          id: id,
        }
    );
  } catch (error) {
    return error;
  }
}
/**
 * 编辑教师备课表
 * @author 四川创企
 * @DateTime 2024/05/06
 * @param {object} params
 */
export async function editTeaTeaching(params) {
  try {
    return await HTTP.post(
        `${PREFIX6}edit`,
        params
    );
  } catch (error) {
    return error;
  }
}

/**
 * 新增教师备课表
 * @author 四川创企
 * @DateTime 2024/05/06
 * @param {object} params
 */
export async function addTeaTeaching(params) {
  try {
    return await HTTP.post(
        `${PREFIX6}add`,
        params
    );
  } catch (error) {
    return error;
  }
}
/**
 * 刪除教师备课表
 * @author 四川创企
 * @DateTime 2024/05/06
 * @param {number} id
 */
export async function deleteTeaTeaching(id) {
  try {
    return await HTTP.post(
        `${PREFIX6}delete`,
        {
          id: id
        }
    );
  } catch (error) {
    return error;
  }
}

/* 知识点 */
/**
*列表
* */
export async function $getPrepareKnowledge(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX7}list_info`,{tetea_id});
  } catch (error) {
    return error;
  }
}
/**
*新增
* */
export async function $addPrepareKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX7}add`,params);
  } catch (error) {
    return error;
  }
}
/**
*编辑
* */
export async function $editPrepareKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX7}edit`,params);
  } catch (error) {
    return error;
  }
}
/**
*删除
* */
export async function $delPrepareKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX7}delete`,params);
  } catch (error) {
    return error;
  }
}
/**
*下载模板
* */
export async function $downloadTemplate(params) {
  try {
    return await HTTP.post(`${PREFIX7}importtemplate`,params);
  } catch (error) {
    return error;
  }
}
/**
*导入
* */
export async function $importKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX7}import`,params);
  } catch (error) {
    return error;
  }
}



/* 作业 */

/**
 *列表
 * */
export async function $getPrepareHomework(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX8}list_info`,{tetea_id});
  } catch (error) {
    return error;
  }
}
/**
 *详情
 * */
export async function $prepareHomeworkDetail(tetea_hom_id) {
  try {
    return await HTTP.post(`${PREFIX8}info`,{ tetea_hom_id });
  } catch (error) {
    return error;
  }
}
/**
 *新增
 * */
export async function $addPrepareHomework(params) {
  try {
    return await HTTP.post(`${PREFIX8}add`,params);
  } catch (error) {
    return error;
  }
}
/**
 *编辑
 * */
export async function $editPrepareHomework(params) {
  try {
    return await HTTP.post(`${PREFIX8}edit`,params);
  } catch (error) {
    return error;
  }
}
/**
 *删除
 * */
export async function $delPrepareHomework(tetea_hom_id) {
  try {
    return await HTTP.post(`${PREFIX8}delete`,{ tetea_hom_id });
  } catch (error) {
    return error;
  }
}
/* 课件 */

/**
 *列表
 * */
export async function $getPrepareCourseware(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX9}list_info`,{tetea_id});
  } catch (error) {
    return error;
  }
}
/**
 *新增
 * */
export async function $uploadPrepareCourseware(params) {
  try {
    return await HTTP.post(`${PREFIX9}add`,params);
  } catch (error) {
    return error;
  }
}
/**
 *删除
 * */
export async function $delPrepareCourseware(id) {
  try {
    return await HTTP.post(`${PREFIX9}delete`,{id});
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 批量导入练习题
 * @Author: Dyf
 * @return {*}
 */
export async function $batchadd(params) {
  try {
    return await HTTP.post(`${PREFIX10}batchadd`,params);
  } catch (error) {
    return error;
  }
}

/* 课堂练习 */

/**
 *列表
 * */
export async function $getPreparePractise(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX10}list_info`,{tetea_id});
  } catch (error) {
    return error;
  }
}
/**
 *详情
 * */
export async function $preparePractiseDetail(tetea_pra_id) {
  try {
    return await HTTP.post(`${PREFIX10}info`,{tetea_pra_id});
  } catch (error) {
    return error;
  }
}
/**
 *新增
 * */
export async function $addPreparePractise(params) {
  try {
    return await HTTP.post(`${PREFIX10}add`,params);
  } catch (error) {
    return error;
  }
}
/**
 *编辑
 * */
export async function $editPreparePractise(params) {
  try {
    return await HTTP.post(`${PREFIX10}edit`,params);
  } catch (error) {
    return error;
  }
}
/**
 *删除
 * */
export async function $delPreparePractise(tetea_pra_id) {
  try {
    return await HTTP.post(`${PREFIX10}delete`,{tetea_pra_id});
  } catch (error) {
    return error;
  }
}

/**
 * 下载批量导入模板
 * 
 */
export async function $downloadPractiseTemplate(params) {
  try {
    return await HTTP.post(`${PREFIX10}importtemplate`,params);
  } catch (error) {
    return error;
  }
}

/**
 * 导入
 * 
 */
export async function $importTeachingPractise(params) {
  try {
    return await HTTP.post(`${PREFIX10}import`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 获取题目列表
 * @param {number} type 题目列表类型 1.我的  2.学校  3.平台
 * @param {object} params 筛选参数
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $getQuesList(type, params, pagesize = PAGE_SIZE) {
  //let url = "/platform/plaquestion/list";
  let url = "/school/schquestionscschid/list";
  try {
    return await HTTP.get(url, { ...params, pagesize },{noControl:true});
  } catch (error) {
    return error;
  }
}